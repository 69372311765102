.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  cursor: pointer;
  pointer-events: auto;
  margin-top: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Menu-image {
  width: 80%;
  height: auto;
  margin-top: 20px;
  border: 2px solid #282c34;
}
